export type Boss = {
  id: number
  name: string
  location: string
  description?: string
}

const bosses: Array<Boss> = [
  {
    id: 0,
    name: 'Ulcerated Tree Spirit',
    location: 'Limgrave',
    description: "Fringefolk Hero's Grave",
  },
  {
    id: 1,
    name: 'Soldier of Godrick',
    location: 'Limgrave',
    description: 'At the end of the Cave of Knowledge dungeon, within the Stranded Graveyard.',
  },
  {
    id: 2,
    name: 'Grafted Scion',
    location: 'Limgrave',
    description: 'In the prologue area',
  },
  {
    id: 3,
    name: 'Tree Sentinel',
    location: 'Limgrave',
    description: 'Patrolling between the Church of Elleh and The First Step.',
  },
  {
    id: 4,
    name: '2x Demi-Human Chief',
    location: 'Limgrave',
    description: 'At the end of the Coastal Cave dungeon.',
  },
  {
    id: 5,
    name: 'Erdtree Burial Watchdog',
    location: 'Limgrave',
    description: 'Past the locked door in Stormfoot Catacombs.',
  },
  {
    id: 6,
    name: 'Beastman of Farum Azula',
    location: 'Limgrave',
    description: 'Groveside Cave',
  },
  {
    id: 7,
    name: 'Crucible Knight',
    location: 'Limgrave',
    description: 'Stormhill Evergaol',
  },
  {
    id: 8,
    name: 'Stonedigger Troll',
    location: 'Limgrave',
    description: 'Limgrave Tunnels',
  },
  {
    id: 9,
    name: 'Flying Dragon Agheel',
    location: 'Limgrave',
    description: 'Dragon-Burnt Ruins',
  },
  {
    id: 10,
    name: 'Bloodhound Knight Darriwil',
    location: 'Limgrave',
    description: 'Forlorn Hound Evergaol  ',
  },
  {
    id: 11,
    name: "Night's Cavalry",
    location: 'Limgrave',
    description: 'Patrols the bridge between Agheel Lake North and Waypoint Ruins. Only appears at night.',
  },
  {
    id: 12,
    name: 'Mad Pumpkin Head',
    location: 'Limgrave',
    description: 'Underneath Waypoint Ruins.',
  },
  {
    id: 13,
    name: 'Grave Warden Duelist',
    location: 'Limgrave',
    description: 'Murkwater Catacombs',
  },
  {
    id: 14,
    name: 'Patches',
    location: 'Limgrave',
    description: 'Murkwater Cave',
  },
  {
    id: 15,
    name: 'Guardian Golem',
    location: 'Limgrave',
    description: 'Highroad Cave',
  },
  {
    id: 16,
    name: 'Black Knife Assassin',
    location: 'Limgrave',
    description: 'Deathtouched Catacombs',
  },
  {
    id: 17,
    name: "Night's Cavalry (Weeping Peninsula)",
    location: 'Limgrave',
    description: 'Weeping Peninsula. Only appears at night.',
  },
  {
    id: 18,
    name: 'Leonine Misbegotten',
    location: 'Limgrave',
    description: 'Castle Morne - At the very rear in the graveyard at sea-level.',
  },
  {
    id: 19,
    name: 'Tibia Mariner',
    location: 'Limgrave',
    description: 'Summonwater Village',
  },
  {
    id: 20,
    name: 'Lion Guardian',
    location: 'Limgrave',
    description: 'Guarding the door to the Limgrave Tower Bridge Site of Grace.',
  },
  {
    id: 21,
    name: 'Root Monster',
    location: 'Limgrave',
    description: 'Underground Crypt',
  },
  {
    id: 22,
    name: 'Demi-Human Queen',
    location: 'Limgrave',
    description: 'Demi-Human Forest Ruins',
  },
  {
    id: 23,
    name: 'Glintstone Dragon Smarag',
    location: 'Liurnia',
    description: 'South-west of the Crystalline Woods Site of Grace.',
  },
  {
    id: 24,
    name: 'Cemetery Shade',
    location: 'Limgrave',
    description: 'Tombsward Catacombs',
  },
  {
    id: 25,
    name: 'Ancient Hero of Zamor',
    location: 'Limgrave',
    description: 'Weeping Evergaol',
  },
  {
    id: 26,
    name: 'Scaly Misbegotten',
    location: 'Limgrave',
    description: 'Morne Tunnel',
  },
  {
    id: 27,
    name: 'Deathbird',
    location: 'Limgrave',
    description: 'Appears at night on top of the ruin covered in Ruin Fragments',
  },
  {
    id: 28,
    name: 'Runebear',
    location: 'Limgrave',
    description: ' Earthbore Cave',
  },
  {
    id: 29,
    name: 'Deathbird (Liurna Lake)',
    location: 'Liurnia',
    description: 'Only found at night',
  },
  {
    id: 30,
    name: 'Red Wolf of Radagon',
    location: 'Liurnia',
    description: 'Debate Hall',
  },
  {
    id: 31,
    name: 'Miranda the Blighted Bloom',
    location: 'Limgrave',
    description: 'Tombsward Cave',
  },
  {
    id: 32,
    name: 'Decaying Ekzykes',
    location: 'Caelid',
    description: 'Guarding the way to Cathedral of Dragon Communion',
  },
  {
    id: 33,
    name: "Erdtree Burial Watchdog (Impaler's Catacombs)",
    location: 'Limgrave',
    description: "Impaler's Catacombs",
  },
  { id: 34, name: 'Greyll', location: 'Caelid', description: 'On the bridge' },
  {
    id: 35,
    name: 'Deathbird',
    location: 'Limgrave',
    description: 'Spawns at night',
  },
  {
    id: 36,
    name: 'Crucible Knight & Misbegotten Warrior',
    location: 'Caelid',
    description: 'Redmane Castle Plaza after defeating Radahn and resting at a Site of Grace',
  },
  { id: 37, name: "Night's Cavalry", location: 'Liurnia' },
  {
    id: 38,
    name: 'Greyoll',
    location: 'Caelid',
    description: 'In front of Fort Faroth Site of Grace.',
  },
  {
    id: 39,
    name: 'Godskin Apostle',
    location: 'Altus Plateau',
    description: 'At Windmill Heights on top of the hill',
  },
  { id: 40, name: 'Magma Wyrm Makar', location: 'Liurnia', description: '??' },
  {
    id: 41,
    name: 'Crystalian (Ringblade)',
    location: 'Liurnia',
    description: 'Raya Lucaria Crystal Tunnel',
  },
  {
    id: 42,
    name: 'Cleanrot Knight',
    location: 'Liurnia',
    description: 'Stillwater Cave',
  },
  {
    id: 43,
    name: 'Omenkiller',
    location: 'Liurnia',
    description:
      'At the end of the path over the stone bridge through the Village of the Albinaurics, down in the caves, not the cathedral above it (?)',
  },
  {
    id: 44,
    name: 'Spear Crystalian & Staff Crystalian',
    location: 'Liurnia',
    description: 'Academy Crystal Cave',
  },
  {
    id: 45,
    name: 'Night Cavalry',
    location: 'Caelid',
    description: 'South of Aeonia Swamp (Appears only at night)',
  },
  {
    id: 46,
    name: 'Black Knife Assassin',
    location: 'Liurnia',
    description:
      'Hidden boss in Black Knife Catacombs - Behind an illusionary wall at the end of the hallway next to the drop to the boss room door switch',
  },
  {
    id: 47,
    name: 'Cemetery Shade (Black Knife Catacombs)',
    location: 'Liurnia',
    description: 'Black Knife Catacombs',
  },
  {
    id: 48,
    name: 'Nox Swordstress & Nox Priest',
    location: 'Caelid',
    description:
      'Behind a magic barrier north of Sellia, Town of Sorcery - you must solve the Sellia Magic Barriers Puzzle to dispell the barrier.',
  },
  {
    id: 49,
    name: 'Death Rite Bird',
    location: 'Liurnia',
    description: 'A Deathbird Spawns around this location at night',
  },
  { id: 50, name: 'Adan, Thief of Fire', location: 'Liurnia' },
  { id: 51, name: 'Erdtree Avatar', location: 'Caelid' },
  {
    id: 52,
    name: 'Putrid Avatar',
    location: 'Caelid',
    description: 'East of the Minor Erdtree',
  },
  {
    id: 53,
    name: "Night's Cavalry",
    location: 'Caelid',
    description: 'On the bridge. Only spawns at night.',
  },
  { id: 54, name: 'Tibia Mariner', location: 'Liurnia', description: '??' },
  {
    id: 55,
    name: 'Tibia Mariner',
    location: 'Mountaintops of the Giants',
    description: 'In the Graveyard along the back side.',
  },
  {
    id: 56,
    name: "Night's Cavalry",
    location: 'Liurnia',
    description: 'Forest near East Raya Lucaria Gate Site of Grace. Only appears at night.',
  },
  {
    id: 57,
    name: 'Godskin Apostle',
    location: 'Caelid',
    description: 'Divine Tower of Caelid',
  },
  {
    id: 58,
    name: 'Erdtree Burial Watchdog Duo',
    location: 'Caelid',
    description: 'Minor Erdtree Catacombs',
  },
  {
    id: 59,
    name: 'Necromancer Garris',
    location: 'Altus Plateau',
    description: "Sage's Cave",
  },
  {
    id: 60,
    name: 'Black Knife Assassin',
    location: 'Altus Plateau',
    description: "Sage's Cave",
  },
  {
    id: 61,
    name: 'Mad Pumpkin Heads (Hammer & Flail)',
    location: 'Caelid',
    description: 'Caelem Ruins  - Underground  ',
  },
  {
    id: 62,
    name: 'Spirit Caller Snail',
    location: 'Liurnia',
    description: "Road's End Catacombs",
  },
  {
    id: 63,
    name: 'Maleigh Marais, Shaded Castle Castellan',
    location: 'Altus Plateau',
    description: 'Just West of The Shaded Castle. Spawns with a Stone Golem.',
  },
  {
    id: 64,
    name: 'Onyx Lord',
    location: 'Liurnia',
    description: 'Royal Grave Evergoal',
  },
  {
    id: 65,
    name: 'Royal Revenant',
    location: 'Liurnia',
    description: 'Kingsrealm Ruins - Underground',
  },
  {
    id: 66,
    name: 'Grave Warden Duelist',
    location: 'Altus Plateau',
    description: 'Auriza Side Tomb',
  },
  {
    id: 67,
    name: 'Death Rite Bird',
    location: 'Caelid',
    description: 'Appears only at night.',
  },
  {
    id: 68,
    name: 'Erdtree Avatar',
    location: 'Mountaintops of the Giants',
    description: 'Base of the Minor Erdtree.',
  },
  {
    id: 69,
    name: 'Ulcerated Tree Spirit',
    location: 'Altus Plateau',
    description: 'Just North of Lower Capital Church Site of Grace in the open area.',
  },
  {
    id: 70,
    name: 'Fell Twin x 2',
    location: 'Altus Plateau',
    description: 'Middle of the bridge.',
  },
  {
    id: 71,
    name: 'Draconic Tree Sentinel',
    location: 'Altus Plateau',
    description: 'Guarding the gate.  ',
  },
  {
    id: 72,
    name: 'Deathbird',
    location: 'Altus Plateau',
    description: 'Only appears at night',
  },
  {
    id: 73,
    name: 'Magma Wyrm',
    location: 'Caelid',
    description: 'Gael Tunnel',
  },
  {
    id: 74,
    name: 'Commander Niall',
    location: 'Mountaintops of the Giants',
    description: 'Castle Sol',
  },
  {
    id: 75,
    name: 'Lesser Black Blade Kindred',
    location: 'Altus Plateau',
    description: 'Visible from the West Capital Rampart',
  },
  {
    id: 76,
    name: 'Wormface',
    location: 'Altus Plateau',
    description: 'Boss at the base of the Minor Erdtree.',
  },
  {
    id: 77,
    name: 'Elemer of the Briar',
    location: 'Altus Plateau',
    description: 'Boss of The Shaded Castle.',
  },
  {
    id: 78,
    name: 'Bols, Carian Knight',
    location: 'Liurnia',
    description: "Cuckoo's Evergaol",
  },
  {
    id: 79,
    name: 'Full-Grown Fallingstar Beast',
    location: 'Altus Plateau',
    description: 'South of Ninth Mt. Gelmir Campsite at the top of the mountain by ladder or air jump (?)',
  },
  {
    id: 80,
    name: 'Demi-Human Queen Margot',
    location: 'Altus Plateau',
    description: 'Volcano Cave.',
  },
  {
    id: 81,
    name: 'Ancient Hero of Zamor',
    location: 'Mountaintops of the Giants',
    description: "Giant-Conquering Hero's Grave",
  },
  {
    id: 82,
    name: 'Fire Giant',
    location: 'Mountaintops of the Giants',
    description: 'By Fire Giant site of grace.  ',
  },
  {
    id: 83,
    name: 'Magma Wyrm',
    location: 'Altus Plateau',
    description: 'Just south of Fort Laied in the Magma pool.',
  },
  {
    id: 84,
    name: 'Putrid Tree Spirit',
    location: 'Caelid',
    description: 'At the end of War-Dead Catacombs',
  },
  {
    id: 85,
    name: 'Godskin Duo',
    location: 'Crumbling Farum Azula',
    description: '[Dragon Temple Altar] site of grace',
  },
  {
    id: 86,
    name: 'Perfumer Tricia & Misbegotten Warrior',
    location: 'Altus Plateau',
    description: 'Unsghtly Catacombs bosses  ',
  },
  {
    id: 87,
    name: 'Battlemage Hugues',
    location: 'Caelid',
    description: 'Sellia Evergaol',
  },
  { id: 88, name: 'Astel, Naturalborn of the Void', location: 'Ainsel River' },
  {
    id: 89,
    name: 'Crucible Knight & Crucible Knight Ordovis',
    location: 'Altus Plateau',
    description: "Auriza Hero's Grave",
  },
  {
    id: 90,
    name: 'Tibia Mariner',
    location: 'Altus Plateau',
    description: '??',
  },
  {
    id: 91,
    name: 'Demi Human Queen Gilika',
    location: 'Altus Plateau',
    description: 'Lux Ruins',
  },
  {
    id: 92,
    name: 'Glintstone Dragon Adula (2nd Location)',
    location: 'Liurnia',
  },
  {
    id: 93,
    name: 'Fallingstar Beast',
    location: 'Caelid',
    description: 'Sellia Crystal Tunnel',
  },
  { id: 94, name: '2 x Tree Sentinels', location: 'Altus Plateau' },
  { id: 95, name: 'Fallingstar Beast', location: 'Altus Plateau' },
  {
    id: 96,
    name: 'Ulcerated Tree Spirit',
    location: 'Altus Plateau',
    description: 'Directly South of Outer Wall Phantom Tree site of grace. It spawns from the center of the camp.',
  },
  {
    id: 97,
    name: 'Maliketh, the Black Blade',
    location: 'Crumbling Farum Azula',
    description: 'Crumbling Farum Azula',
  },
  {
    id: 98,
    name: 'Godefroy the Grafted',
    location: 'Altus Plateau',
    description: 'Golden Lineage Evergaol',
  },
  {
    id: 99,
    name: 'Sanguine Noble',
    location: 'Altus Plateau',
    description: 'Writheblood Ruins, in the underground crypt.',
  },
  {
    id: 100,
    name: 'Ulcerated Tree Spirit',
    location: 'Mountaintops of the Giants',
  },
  {
    id: 101,
    name: 'Black Knife Assassin',
    location: 'Altus Plateau',
    description: "Guarding the door to Sainted Hero's Grave",
  },
  {
    id: 102,
    name: 'Ancestor Spirit',
    location: 'Siofra River',
    description: 'Hallowhorn Grounds',
  },
  { id: 103, name: 'Ulcerated Tree Spirit', location: 'Altus Plateau' },
  { id: 104, name: 'Demi-Human Queen Maggie', location: 'Altus Plateau' },
  {
    id: 105,
    name: "Night's Cavalry",
    location: 'Altus Plateau',
    description:
      'Patrols the road between Waypoint Altus Highway Junction and Grand Lift of Dectus. Only appears at night.',
  },
  {
    id: 106,
    name: 'Beastman of Farum Azula',
    location: 'Caelid',
    description: 'Dragonbarrow Cave',
  },
  {
    id: 107,
    name: 'The Three Crystalians',
    location: 'Caelid',
    description: 'At the end of Sellia Hideaway.',
  },
  { id: 108, name: 'Dragonkin Soldier', location: 'Siofra River' },
  {
    id: 109,
    name: 'Margit, The Fell Omen (Leyndell)',
    location: 'Altus Plateau',
  },
  {
    id: 110,
    name: 'Death Rite Bird',
    location: 'Mountaintops of the Giants',
    description: 'On the cliffside, north of Shack of the Lofty',
  },
  {
    id: 111,
    name: 'Black Blade Kindred',
    location: 'Mountaintops of the Giants',
    description: 'Base of the Grand Lift of Rold',
  },
  {
    id: 112,
    name: 'Frenzied Duelist',
    location: 'Caelid',
    description:
      'At the end of Gaol Cave. You need to use the lever to open the cells, in one of the last there is a large room with two corpses. In the right of this room hide a passage behind a wooden planck wall. The boss is below it.',
  },
  {
    id: 113,
    name: 'Cristalian Spear & Cristalian Ringblade',
    location: 'Altus Plateau',
  },
  { id: 114, name: 'Red Wolf of the Champion', location: 'Altus Plateau' },
  {
    id: 115,
    name: 'Onyx Lord',
    location: 'Altus Plateau',
    description: 'Sealed Tunnel',
  },
  { id: 116, name: 'Lichdragon Fortissax', location: 'Deeproot Depths' },
  {
    id: 117,
    name: 'Godskin Noble',
    location: 'Altus Plateau',
    description: 'Temple of Eiglay',
  },
  {
    id: 118,
    name: 'Great Wyrm Theodorix',
    location: 'Mountaintops of the Giants',
  },
  {
    id: 119,
    name: 'Cleanrot Knight (Spear) & Cleanrot Knight (Sickle)',
    location: 'Caelid',
    description: 'Final Boss(es) of the Abandoned Cave',
  },
  {
    id: 120,
    name: 'Astel, Stars of Darkness',
    location: 'Mountaintops of the Giants',
  },
  {
    id: 121,
    name: 'Loretta, Knight of the Haligtree',
    location: 'Mountaintops of the Giants',
    description: 'Haligtree Promenade',
  },
  {
    id: 122,
    name: 'Godskin Noble',
    location: 'Liurnia',
    description: 'On the bridge.',
  },
  {
    id: 123,
    name: 'Mimic Tear',
    location: 'Siofra River',
    description: 'Nokron, Eternal City',
  },
  {
    id: 124,
    name: 'Crucible Knight Sirulia',
    location: 'Deeproot Depths',
    description: 'Deeproot Depths',
  },
  {
    id: 125,
    name: 'Vyke, Knight of the Roundtable',
    location: 'Mountaintops of the Giants',
    description: "Lord Contender's Evergoal",
  },
  {
    id: 126,
    name: 'Putrid Grave Warden Duelist',
    location: 'Mountaintops of the Giants',
    description: 'Consecrated Snowfield Catacombs',
  },
  {
    id: 127,
    name: 'Valiant Gargoyle & Valiant Gargoyle (Twinblade)',
    location: 'Siofra River',
  },
  { id: 128, name: "Fia's Champions", location: 'Deeproot Depths' },
  { id: 129, name: 'Erdtree Avatar', location: 'Deeproot Depths' },
  {
    id: 130,
    name: "2x Night's Cavalry",
    location: 'Mountaintops of the Giants',
    description: 'Guarding the wagon in the Snowfields',
  },
  {
    id: 218,
    name: "Night's Cavalry (Forbidden Lands)",
    location: 'Mountaintop of the Giants',
    description: 'Past the Forbidden Lands grace',
  },
  {
    id: 131,
    name: 'Stonedigger Troll',
    location: 'Altus Plateau',
    description: ' Old Altus Tunnel',
  },
  {
    id: 132,
    name: 'Ancient Dragon Lansseax',
    location: 'Altus Plateau',
    description:
      'Just southwest of Rampartside Path site of grace on the plateau.  Only appears after defeating in first location. May also only appear at night time here (?)',
  },
  {
    id: 133,
    name: 'Kindred Rot x 2',
    location: 'Altus Plateau',
    description: '[Seethewater Cave] Boss.',
  },
  {
    id: 134,
    name: 'Erdtree Burial Watchdog',
    location: 'Altus Plateau',
    description: 'Wyndham Catacombs',
  },
  {
    id: 135,
    name: 'Abductor Virgins x2',
    location: 'Altus Plateau',
    description: 'Subterranean Inquisition Chamber',
  },
  {
    id: 136,
    name: 'Dragonkin Soldier',
    location: 'Ainsel River',
    description: 'Found dormant in the middle of the Lake of Rot.',
  },
  {
    id: 137,
    name: 'Erdtree Burial Watchdog',
    location: 'Liurnia',
    description: 'At the end of Cliffbottom Catacombs.',
  },
  {
    id: 138,
    name: 'Cemetery Shade',
    location: 'Caelid',
    description:
      'At the end of Caelid Catacombs. Activate the lever hidden behind an invisible wall on the central pillar in front of the boss door.',
  },
  { id: 139, name: "Night's Cavalry", location: 'Altus Plateau' },
  { id: 140, name: 'Death Rite Bird', location: 'Mountaintops of the Giants' },
  { id: 141, name: 'Putrid Avatar', location: 'Mountaintops of the Giants' },
  {
    id: 142,
    name: 'Dragonlord Placidusax',
    location: 'Crumbling Farum Azula',
    description:
      'from "Beside the Great Bridge" Grace site, go down the elevator, walk down stairs and outside, continue straight towards the trees, and look over the edge for a platform.',
  },
  {
    id: 143,
    name: 'Bloodhound Knight',
    location: 'Liurnia',
    description: 'At the end of Lakeside Crystal Cave, after lowering down the pit.',
  },
  {
    id: 144,
    name: 'Misbegotten Crusader',
    location: 'Mountaintops of the Giants',
    description: 'Cave of the Forlorn',
  },
  {
    id: 145,
    name: 'Omenkiller',
    location: 'Altus Plateau',
    description: "Perfumer's Ruins",
  },
  {
    id: 146,
    name: 'Miranda, the Blighted Bloom & Omenkiller',
    location: 'Altus Plateau',
    description: "Perfumer's Grotto",
  },
  {
    id: 147,
    name: 'Omenkiller',
    location: 'Altus Plateau',
    description: 'Volcano Manor, between the Guest Hall and the Prison Town Church.',
  },
  {
    id: 148,
    name: 'Prelate',
    location: 'Altus Plateau',
    description: 'Fort Laied',
  },
  {
    id: 149,
    name: 'Cheif Guardian Arghanthy',
    location: 'Mountaintops of the Giants',
    description: "[Guardian's Garrison] Ramparts.",
  },
  { id: 150, name: 'Stray Mimic Tear', location: 'Mountaintops of the Giants' },
  {
    id: 151,
    name: 'Spiritcaller Snail',
    location: 'Mountaintops of the Giants',
  },
  {
    id: 152,
    name: 'Alexander Iron Fist (Crumbling Farum Azula)',
    location: 'Crumbling Farum Azula',
    description: 'In a flat arena, past the crumbling tower. Jump on the archway to reach him.',
  },
  {
    id: 153,
    name: 'Alecto, Black Knife Ringleader',
    location: 'Liurnia',
    description: "Ringleader's Evergaol.",
  },
  {
    id: 154,
    name: 'Ancient Hero of Zamor',
    location: 'Altus Plateau',
    description: "At the end of Sainted Hero's grave. A Duelist must be defeated first to open the boss door.",
  },
  {
    id: 155,
    name: 'Esgar, Priest of Blood',
    location: 'Altus Plateau',
    description: 'At the end of Leyndell Catacombs, accessible by Subterranean Shunning Grounds.',
  },
  { id: 156, name: 'Beast Clergyman', location: 'Crumbling Farum Azula' },
  {
    id: 157,
    name: 'Sir Gideon Ofnir, the All-Knowing',
    location: 'Altus Plateau',
    description: 'Erdtree Sanctuary AFTER defeating Maliketh, the Black Blade.',
  },
  {
    id: 158,
    name: 'Hoarah Loux, Warrior',
    location: 'Altus Plateau',
    description: 'Appear on the Elden Throne in Capital of Ash, after defeating Maliketh',
  },
  {
    id: 159,
    name: 'Margit, the Fell Omen',
    location: 'Limgrave',
    description: 'Just past Castleward Tunnel',
  },
  {
    id: 160,
    name: 'Erdtree Avatar',
    location: 'Limgrave',
    description: 'Weeping Peninsula Erdtree',
  },
  {
    id: 161,
    name: 'Royal Knight Loretta',
    location: 'Liurnia',
    description: 'Caria Manor',
  },
  { id: 162, name: 'Erdtree Avatar', location: 'Liurnia' },
  { id: 163, name: 'Erdtree Avatar', location: 'Liurnia' },
  {
    id: 164,
    name: 'Borealis the Freezing Fog',
    location: 'Mountaintops of the Giants',
    description: 'In the center of the frozen lake',
  },
  {
    id: 165,
    name: 'Black Blade Kindred',
    location: 'Caelid',
    description: 'Outside the Bestial Sanctum',
  },
  {
    id: 166,
    name: "Commander O'Neil",
    location: 'Caelid',
    description: 'On the Heart of Aeonia site of grace, the site appears after defeating him. ',
  },
  {
    id: 167,
    name: 'Glintstone Dragon Adula',
    location: 'Liurnia',
    description:
      "Found dwelling in front of Ranni's Rise. Will teleport away to Cathedral of Manus Celes in southwest Liurnia when down to below 50% health.",
  },
  { id: 168, name: 'Ancient Dragon Lansseax', location: 'Altus Plateau' },
  { id: 169, name: 'Dragonkin Soldier of Nokstella', location: 'Ainsel River' },
  { id: 170, name: 'Regal Ancestor Spirit', location: 'Siofra River' },
  { id: 171, name: 'Erdtree Avatar', location: 'Altus Plateau' },
  {
    id: 172,
    name: 'Mohg, the Omen',
    location: 'Altus Plateau',
    description: 'Found in the Subterranean Shunning-Grounds (?). Accessible from Forsaken Depths',
  },
  {
    id: 173,
    name: 'Bloody Finger Nerijus',
    location: 'Limgrave',
    description: 'Between Murkwater Catacombs and Murkwater Cave.',
  },
  {
    id: 174,
    name: 'Anastasia, Tarnished-Eater',
    location: 'Limgrave',
    description: '??',
  },
  { id: 175, name: 'Recusant Henricus', location: 'Limgrave' },
  { id: 176, name: 'Bell Bearing Hunter', location: 'Limgrave' },
  {
    id: 177,
    name: 'Bell Bearing Hunter (Church of Vows)',
    location: 'Liurnia',
    description: 'Church of Vows - Rest at night until Miriel disappears.',
  },
  {
    id: 178,
    name: 'Bloody Finger Okina',
    location: 'Mountaintops of the Giants',
    description: 'Church of Repose',
  },
  {
    id: 179,
    name: 'Moongrum, Carian Knight',
    location: 'Liurnia',
    description: 'Just before the lift to Raya Lucaria Grand Library.',
  },
  {
    id: 180,
    name: 'Anastasia, Tarnished-Eater',
    location: 'Altus Plateau',
    description: 'Just before Corpse-Stench Shack.',
  },
  {
    id: 181,
    name: 'Millicent',
    location: 'Caelid',
    description: 'In the middle of the Swamp of Aeonia',
  },
  {
    id: 182,
    name: 'Bloody Finger Assassin',
    location: 'Liurnia',
    description: 'Past the magic portal, on the ground at the end of the broken bridge.',
  },
  {
    id: 183,
    name: 'Edgar The Revenger',
    location: 'Liurnia',
    description: "Revenger's Shack. Rest at the Site of Grace to trigger the ivasion.",
  },
  {
    id: 184,
    name: 'Festering Fingerprint Vyke',
    location: 'Liurnia',
    description: "Church of Inhibition - After completing the 4th part of Hyetta's quest (?)",
  },
  { id: 185, name: 'Ensha of the Royal Remains', location: 'Roundtable Hold' },
  { id: 186, name: 'Preceptor Miriam', location: 'Liurnia' },
  { id: 187, name: 'Sanguine Noble', location: 'Mountaintops of the Giants' },
  { id: 188, name: 'Bell Bearing Hunter', location: 'Caelid' },
  {
    id: 189,
    name: 'Eleonora, Violet Bloody Finger',
    location: 'Altus Plateau',
    description: 'At Second Church of Marika; triggered by speaking with Yura.',
  },
  {
    id: 190,
    name: 'Recusant Bernahl',
    location: 'Crumbling Farum Azula',
    description: 'Crumbling Farum Azula',
  },
  {
    id: 191,
    name: 'Vargram, the Raging Wolf',
    location: 'Altus Plateau',
    description: "Near Fortified Manor, First Floor. Related to Recusant Bernahl's quest in the Volcano Manor.",
  },
  {
    id: 192,
    name: 'Juno Hoslow, Knight of Blood',
    location: 'Mountaintops of the Giants',
    description: 'Near Shack of the Lofty, 3rd assassination target for the Volcano Manor quests.',
  },
  { id: 193, name: 'Baleful Shadow', location: 'Ainsel River' },
  {
    id: 194,
    name: 'Inquisitor Ghiza',
    location: 'Altus Plateau',
    description: 'Upstairs in Volcano Manor',
  },
  {
    id: 195,
    name: 'Anastasia, Tarnished-Eater',
    location: 'Mountaintops of the Giants',
  },
  { id: 196, name: 'Mad Tongue Alberich', location: 'Roundtable Hold' },
  {
    id: 198,
    name: 'Great Horned Tragoth',
    location: 'Liurnia',
    description:
      "Ruin-Strewn Precipice Overlook. Triggered by invading his world through the sigil. Assassination target related to Patches' Quest in Volcano Manor.",
  },
  {
    id: 199,
    name: 'Old Knight Istvan',
    location: 'Limgrave',
    description: 'Underneath the Divine Bridge, 1st assassination target for the Volcano Manor quests.',
  },
  {
    id: 200,
    name: 'Dung Eater',
    location: 'Altus Plateau',
    description: 'In the moat, near Leyndell, Royal Capital.',
  },
  { id: 201, name: 'Bell Bearing Hunter', location: 'Altus Plateau' },
  { id: 202, name: 'Duelist Knight of the Great Jar x3', location: 'Caelid' },
  {
    id: 203,
    name: 'Rileigh the Idle',
    location: 'Altus Plateau',
    description: 'South from The Shaded Castle, 2nd assassination target for the Volcano Manor quests.',
  },
  { id: 204, name: 'Nameless White Mask', location: 'Siofra River' },
  { id: 205, name: 'Nameless White Mask', location: 'Siofra River' },
  { id: 206, name: 'Nameless White Mask', location: 'Siofra River' },
  { id: 207, name: "Tanith's Crucible Knight", location: 'Altus Plateau' },
  {
    id: 208,
    name: 'Godrick the Grafted',
    location: 'Limgrave',
    description: 'Stormveil Castle',
  },
  {
    id: 209,
    name: 'Rennala, Queen of the Full Moon',
    location: 'Liurnia',
    description: 'Raya Lucaria Academy',
  },
  {
    id: 210,
    name: 'Starscourge Radahn',
    location: 'Caelid',
    description: 'Wailing Dunes',
  },
  {
    id: 211,
    name: 'Morgott, the Omen King',
    location: 'Altus Plateau',
    description: "Southeast of Queen's Bedchamber Site of Grace.",
  },
  {
    id: 212,
    name: 'Rykard, Lord of Blasphemy',
    location: 'Altus Plateau',
    description: 'Mt. Gelmir',
  },
  {
    id: 213,
    name: 'Malenia, Blade of Miquella',
    location: 'Mountaintops of the Giants',
    description: '??',
  },
  {
    id: 214,
    name: 'Godfrey, First Elden Lord (Golden Shade)',
    location: 'Altus Plateau',
    description: 'At the [Erdtree Sanctuary] site of grace.',
  },
  {
    id: 215,
    name: 'Mohg, Lord of Blood',
    location: 'Siofra River',
    description: 'At the top of the mausoleum in Mohgwyn Palace. ',
  },
  {
    id: 216,
    name: 'Radagon of the Golden Order',
    location: 'Altus Plateau',
    description: 'Within the Erdtree. He is part one of a two part final boss fight.',
  },
  {
    id: 217,
    name: 'Elden Beast',
    location: 'Altus Plateau',
    description:
      'Within the Erdtree. It is part two of the final boss fight. It will automatically begin on defeating Radagon of the Golden Order',
  },
]
export default bosses
